<template>
  <v-row class="page-breadcrumb mb-0">
    <v-col class="pa-0" cols="12" md="7">
      <v-card class="px-2" flat>
        <v-card-title class="text--primary">{{ getTitle() }}</v-card-title>
        <v-card-subtitle>
          <v-breadcrumbs :items="getBreadcrumbs()" class="pa-0">
            <template v-if="breadcrumbs" v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-card-subtitle>
      </v-card>
    </v-col>
    <v-col class="pa-0 ma-0 d-none d-sm-flex" cols="12" md="5">
      <v-card class="px-2 ma-0" flat>
        <v-card-text class="ma-0">
          <v-chip-group column>
            <propertyTypeChips/>
            <v-chip v-if="valueRange.active" dark small
                    v-bind:outlined="!valueRange.active" class="px-2"
                    @click:close="setValueRangeActive(false)" @click="setValueRangeActive(!valueRange.active)"
            >

              £{{ valueRange.low / 1000 }}k - {{ displayHigh }}
            </v-chip>
            <estateTypeChips/>


            <v-menu
                    :close-on-content-click="false"
                    close-on-click offset-y>
              <template v-slot:activator="{ on, attrs}">

              <v-chip small class="px-2">
                <v-btn small text v-bind="attrs" v-on="on">
                <v-icon class="mr-1 text-right">{{ getSortIcon }}</v-icon>
                  {{ sortBy.value }}

<!--                <v-btn icon v-on:click="setSortOrder(!sort.desc)">-->
<!--                <v-icon v-if="!sort.desc">mdi-arrow-up</v-icon>-->
<!--                <v-icon v-else>mdi-arrow-down</v-icon>-->
              </v-btn>
              </v-chip>
                </template>
                <v-card>
                  <v-card-title>Sort</v-card-title>
                  <v-card-text>
                  <v-select return-object persistent-hint hint="Sort On"  :items="keys" item-text="value" item-value="text" v-model="sortBy"/>
                    <v-select persistent-hint hint="Sort Order"  :items="['Ascending','Descending']" v-model="sortOrder"/>
                  </v-card-text>
                </v-card>

            </v-menu>

          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>

import {mapMutations, mapState} from "vuex";
import PropertyTypeChips from "@/components/commonComponents/PropertyTypeChips";
import EstateTypeChips from "@/components/commonComponents/EstateTypeChips";

export default {
  name: "BaseBreadcrumb",
  components: {EstateTypeChips, PropertyTypeChips},
  props: {
    title: {
      type: String,
      default: 'goodArea.co.uk'
    },
    breadcrumbs: {
      type: Array,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      keys: [
        {text: 'address', value: 'Address', icon: 'mdi-map-marker'},
        {text: 'postcode', value: 'Post Code', icon: 'mdi-map-marker'},
        {text: 'currentValue', value: 'Estimated Value', icon: 'mdi-relative-scale'},
        {text: 'deed_date', value: 'Latest Sale Date', icon: 'mdi-calendar'},
        {text: 'price_paid', value: 'Latest Price Paid', icon: 'mdi-currency-gbp'},
      ],
    }
  },
  computed: {
    ...mapState([ "valueRange", "sort"]),
    sortOrder: {
      get() {
        return this.sort.desc ? 'Descending' : 'Ascending'
      },
      set(sortOrder) {
          this.setSortOrder(sortOrder === 'Descending')
      }
    },
    getSortIcon() {
      switch (this.sort.by) {
        case 'address':
          return this.sort.desc ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending'
        case 'postcode':
          return this.sort.desc ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending'

        case 'currentValue':
          return this.sort.desc ? 'mdi-sort-numeric-descending' : 'mdi-sort-numeric-ascending'
        case 'price_paid':
          return this.sort.desc ? 'mdi-sort-numeric-descending' : 'mdi-sort-numeric-ascending'

        case 'deed_date':
          return this.sort.desc ? 'mdi-sort-calendar-descending' : 'mdi-sort-calendar-ascending'


        default:
          return 'mdi-sort'

      }

    },
    displayHigh() {
      if (this.valueRange.high === null) {
        return 'Unlimited'
      } else if (this.valueRange.high < 1000000) {
        return "£" + this.valueRange.high / 1000 + 'k'
      } else {
        return "£" + this.valueRange.high / 1000000 + 'ml'
      }
    },
    sortBy: {
      get() {
        return {
          text: this.sort.by,
          value: this.keys.find(element => element.text === this.sort.by).value,
        }
      },
      set(obj) {
        this.setSortBy(obj.text)
      }
    },


  },
  methods: {
    ...mapMutations({
      setLow: "SET_LOW",
      setHigh: "SET_HIGH",
      setValueRangeActive: "SET_RANGE_ACTIVE",
      setSortOrder: 'SET_SORTORDER',
      setSortBy: 'SET_SORTBY'
    }),
    getTitle() {
      let title = []
      if (this.getLocality() !== undefined && this.getLocality() !== this.getTown()) {
        title.push(this.getLocality())
      }

      if (this.getTown() !== undefined) {
        title.push(this.getTown())
      }

      if (this.$route.params.postcode !== undefined) {
        title.push(this.$route.params.postcode)
      }

      return title.join(', ')
    },
    getLocality() {
      return this.$route.params.locality
    },
    getTown() {
      return this.$route.params.town
    },
    getPostcode() {
      return this.$route.params.postcode
    },
    getBreadcrumbs() {
      let breadcrumbs = []
      if (this.getTown() !== undefined) {
        breadcrumbs.push({
          text: this.getTown(),
          to: {name: 'summary-town', params: {town: this.getTown() }},
          exact: true
        })
      }

      if (this.getLocality() !== undefined && this.getTown() !== this.getLocality()) {
        breadcrumbs.push({
          text: this.getLocality(),
          to: {
                   name: 'summary-town-locality',
                   params: {town: this.getTown(), locality: this.getLocality()}
          },
          exact: true
        })
      }

      if (this.getPostcode() !== undefined) {
        breadcrumbs.push({
          text: this.getPostcode(),
          to: {name: 'summary-town-locality-postcode', params: {town: this.getTown(), locality: this.getLocality(), postcode: this.getPostcode() }},
          exact: true
        })
      }

      return breadcrumbs
    }
  },
}
;
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -24px -25px 27px -25px;
}
</style>