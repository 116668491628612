<template>
  <div>
  <v-tooltip bottom v-for="(type, key) of propertyType" v-bind:key="key">
    <template v-slot:activator="{ on, attrs }">
      <v-chip small
              v-bind:outlined="!type.filter"
              v-bind:color="type.filter ? type.color : 'grey'"
              v-bind="attrs"
              v-on="on"
              class="px-2 mr-2"
              dark
              @click="setPropertyType({type: key, filter: !type.filter})"
              @click:close="setPropertyType({type: key, filter: !type.filter})"
      >
        <v-icon class="mr-1 text-right">{{ type.icon }}</v-icon>
        {{ type.title }}
      </v-chip>
    </template>
    <span>{{type.title}} are shown in {{type.color}}</span>
  </v-tooltip>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: 'propertyTypeChips',
  computed: {
    ...mapState(["propertyType"]),
    name() {
      return this.data
    }
  },
  methods: {
    ...mapMutations({
      setPropertyType: "SET_PROPERTY_TYPE"
    })

    }
}
</script>
