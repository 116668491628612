<template>
  <div>
    <v-chip-group>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
            small
            @click="setEstateType({type: 'F', filter: !estateType.F.filter})"
            @click:close="setEstateType({type: 'F', filter: !estateType.F.filter})"
            v-bind:outlined="!estateType.F.filter" class="px-2 mr-2"
            v-bind="attrs"
            v-on="on"
        >
          <v-icon class="mr-1 text-right">{{ estateType.F.filter ? 'mdi-circle' : 'mdi-circle-outline' }}</v-icon>
          {{ estateType.F.title }}
        </v-chip>
      </template>
      <span>Freehold properties are listed with a circle background</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
            @click="setEstateType({type: 'L', filter: !estateType.L.filter})"
            @click:close="setEstateType({type: 'L', filter: !estateType.L.filter})"
            v-bind:outlined="!estateType.L.filter" class="px-2 mr-2"
            v-bind="attrs"
            v-on="on"

            small label>
          <v-icon class="mr-1 text-right">{{ estateType.L.filter ? 'mdi-square' : 'mdi-square-outline' }}</v-icon>
          {{ estateType.L.title }}
        </v-chip>
      </template>
      <span>Leasehold properties are listed with a square background</span>
    </v-tooltip>
    </v-chip-group>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: 'estateTypeChips',
  computed: {
    ...mapState([ "estateType"]),  },
  methods: {
    ...mapMutations({
      setEstateType: "SET_ESTATE_TYPE",
    }),
  },
}
</script>
