import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            name: 'home',
            path: '/',
            component: () => import('@/views/home')
        },
        {
            path: "/properties",
            name: "listing",
            redirect: "/",
            props: true,
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: 'listing-postcode',
                    path: 'postcode/:postcode',
                    props: true,
                    component: () => import('@/views/postcode/listing.vue'),
                },
                {
                    name: 'listing-town-locality-postcode',
                    path: ':town/:locality/:postcode',
                    props: true,
                    component: () => import('@/views/postcode/listing.vue'),
                },
                {
                    name: 'listing-town-locality',
                    path: ':town/:locality',
                    props: true,
                    component: () => import('@/views/postcode/listing.vue'),
                },

                {
                    name: 'listing-town',
                    path: ':town',
                    props: true,
                    component: () => import('@/views/postcode/listing.vue'),
                },

            ]
        },
        {
            path: "/summary",
            name: "summary",
            redirect: "/",
            props: true,
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: 'summary-postcode',
                    path: 'postcode/:postcode',
                    props: true,
                    component: () => import('@/views/postcode/summary.vue'),
                },
                {
                    name: 'summary-town-locality-postcode',
                    path: ':town/:locality/:postcode',
                    props: true,
                    component: () => import('@/views/postcode/summary.vue'),
                },
                {
                    name: 'summary-town-locality',
                    path: ':town/:locality',
                    props: true,
                    component: () => import('@/views/postcode/summary.vue'),
                },

                {
                    name: 'summary-town',
                    path: ':town',
                    props: true,
                    component: () => import('@/views/postcode/summary.vue'),
                }

            ]
        },
        {path: "/map",
        props: true,
            component: () => import("@/layouts/full-layout/Layout"),
        children: [{
            name: 'heat',
            props: true,
            path: 'heat',
            component: () => import('@/views/maps/map.vue')
        }
        ]},
        {
            path: '/404', component: () => import ('@/views/NotFound') },
        {
            path: '*', redirect: '/404'
        },

    ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

export default router;
