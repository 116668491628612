import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarBg: "",
        navbarColor: "primary",
        setHorizontalLayout: true, // Horizontal layout
        location: {
            postCode: null,
            locality: null,
            street: null,
            town: null,
            district: null,
        },
        sort: {
            by: 'price_paid',
            desc: true,
        },
        propertyType: {
            D: {
                filter: true, icon: 'mdi-home-group', color: 'blue', title: 'Detached'
            },
            S: {
                filter: true, icon: 'mdi-home', color: 'green', title: 'Semi-detached'
            },
            F: {
                filter: true, icon: 'mdi-office-building', color: 'cyan', title: 'Flats'
            },
            T: {
                filter: true, icon: 'mdi-home-modern', color: 'amber', title: 'Terraced'
            },
            O: {
                filter: false, icon: 'mdi-home-roof', color: 'blue-grey', title: 'Other'
            },

        }
        ,
        estateType: {
            L: {
                filter: true,
                title: 'Leasehold'
            },
            F: {
                filter: true,
                title: 'Freehold'
            }
        },
        valueRange: {
            low: 425000,
            high: 475000,
            active: false,
        }
    },
    mutations: {
        SET_PROPERTY_TYPE(state, payload) {
            // we always want one property type selected, so need to check whether the user choice is to remove the last one
            let selected = 0
            for (const typeSelected in state.propertyType) {
                if (state.propertyType[typeSelected].filter) {
                    selected ++
                }
            }

            if (payload.filter === true || selected > 1) {
                state.propertyType[payload.type].filter = payload.filter
            }
        },
        SET_ESTATE_TYPE(state,payload) {
            state.estateType[payload.type].filter = payload.filter
        },
        SET_LOW(state, payload) {
            state.valueRange.low = payload
            state.valueRange.active = true
        },
        SET_HIGH(state, payload) {
            state.valueRange.high = payload
            state.valueRange.active = true
        },
        SET_RANGE_ACTIVE(state, payload) {
            state.valueRange.active = payload
        },
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload;
        },
        SET_LAYOUT(state, payload) {
            state.setHorizontalLayout = payload;
        },
        SET_SORTORDER(state, payload) {
            state.sort.desc = payload
        },
        SET_SORTBY(state, payload) {
            state.sort.by = payload
        }


    },
    actions: {
        setSortOrder(context) {
            context.commit()
        }
    },
    getters: {
        getSortBy: state => state.sort.by,
        getSortDesc: state => state.sort.desc
    },
});
